import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import { getDaysToExpiration } from '@/utils/getDaysToExpiration'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    me: null,
    userAvatars: [],
    companyLogos: [],
    dayShowExtra: null,
    deletingCodelistId: null,
    ideasContainerStatus: false,
    selectedDate: new Date(new Date().setHours(0, 0, 0, 0)),
    dateRangeFrom: new Date(new Date().setHours(0, 0, 0, 0)),
    dateRangeTo: new Date(new Date().setHours(23, 59, 59, 0)),
    dragInProgress: false,
    activeDropZone: '',
    selectedCompany: {
      expirationDays: 0,
      showExpirationWarning: false,
    },
    selectedGroupBy: 'state',
    selectedGroupByIdea: 'channel',
    contentFilter: [],
    tags: [],
    users: [],
    channels: [],
    owner: 'all',
    showCreateContentModal: false,
    duplicateContentModalId: '',
    createContentDefaultValue: {
      date: null,
      state: null,
      channel: null,
    },
    modalConfirmCloseCreateContent: false,
    modalConfirmCloseEditContent: false,
    modalConfirmCloseEditContentData: {},
    modalUserRolesInfo: false,
    modalFeedback: false,
    modalFeedbackContentId: null,
    showEditContentModal: {},
    showContactModal: false,
    refreshContent: new Date().getTime(),
    refreshUserAvatar: new Date().getTime(),
    refreshCompanyLogo: new Date().getTime(),
    codelist: {},
    slickUpdate: '',
    activeUID: '',
    contentToDelete: {},
    userProfile: '',
    companies: [],
    currentCompany: {},
    prevRouteName: '',
    listCollapsedAll: false,
    ideasCollapsedAll: false,
  },
  getters: {
    getListCollapsedAll: (state) => {
      return state.listCollapsedAll
    },

    getIdeasCollapsedAll: (state) => {
      return state.ideasCollapsedAll
    },

    getFeedbackModal: (state) => {
      return state.modalFeedback
    },

    getUserAvatar: (state) => (userId) => {
      return state.userAvatars.find(
        (userAvatar) => userAvatar.userId === userId
      )
    },

    getCompanyLogo: (state) => (companyId) => {
      return state.companyLogos.find(
        (companyLogo) => companyLogo.companyId === companyId
      )
    },

    getMeCompanyRole: (state, getters) => {
      const codelist = getters.getCodelist

      if (!codelist?.users) {
        return 'READ_ONLY'
      }

      if (!state.me?.id) {
        return 'READ_ONLY'
      }

      const userFromCompany = codelist.users.find(
        (user) => state.me.id === user.id
      )
      if (userFromCompany?.role === 'ADMIN') {
        return 'ADMIN'
      }

      return 'READ_ONLY'
    },

    getCurrentCompany: (state) => {
      return state.currentCompany
    },

    getCompanies: (state) => {
      return state.companies
    },

    getUserProfile: (state) => {
      return state.userProfile
    },

    getMe: (state) => {
      return state.me
    },

    getDayShowExtra: (state) => {
      return state.dayShowExtra
    },

    getDeletingCodelistId: (state) => {
      return state.deletingCodelistId
    },

    getIdeasContainerStatus: (state) => {
      return state.ideasContainerStatus
    },
    getActiveUID: (state) => {
      return state.activeUID
    },

    loggedUser: (state) => {
      return state.codelist.users[0]
    },

    getRefreshContent: (state) => {
      return state.refreshContent
    },

    getRefreshUserAvatar: (state) => {
      return state.refreshUserAvatar
    },

    getRefreshCompanyLogo: (state) => {
      return state.refreshCompanyLogo
    },

    getContactModal: (state) => {
      return state.showContactModal
    },

    getSlickUpdate: (state) => {
      return state.slickUpdate
    },

    getCodelist: (state) => {
      return state.codelist
    },

    getCreateContentModalStatus: (state) => {
      return state.showCreateContentModal
    },

    getDuplicateContentModalId: (state) => {
      return state.duplicateContentModalId
    },

    getCreateContentDefaultVale: (state) => {
      return state.createContentDefaultValue
    },

    getEditContentModalStatus: (state) => {
      return state.showEditContentModal
    },

    getContentOwner: (state) => {
      return state.owner
    },

    getTags: (state) => {
      return state.tags
    },

    getUsers: (state) => {
      return state.users
    },

    getChannels: (state) => {
      return state.channels
    },

    getSelectedDate: (state) => {
      return state.selectedDate
    },

    getDateRangeFrom: (state) => {
      return state.dateRangeFrom
    },

    getDateRangeTo: (state) => {
      return state.dateRangeTo
    },

    getDateRangeDiff: (state) => {
      let a = moment(
        new Date(new Date(state.dateRangeFrom).setHours(0, 0, 0, 0)),
        'M/D/YYYY'
      )
      let b = moment(
        new Date(new Date(state.dateRangeTo).setHours(23, 59, 59, 0)),
        'M/D/YYYY'
      )
      const days = Math.ceil(moment.duration(b.diff(a)).asDays())
      return days
    },

    getFormattedRange: (state) => {
      let _dateFrom = new Date(state.dateRangeFrom)
      _dateFrom.setHours(0, 0, 0, 0)

      let _dateTo = new Date(state.dateRangeTo)
      _dateTo.setHours(0, 0, 0, 0)

      // Check if is same day
      if (_dateFrom.getTime() === _dateTo.getTime()) {
        return moment(state.dateRangeFrom).format('dddd, Do MMM YYYY')
      }

      if (_dateFrom.getFullYear() !== _dateTo.getFullYear()) {
        return (
          moment(state.dateRangeFrom).format('D MMM, YYYY') +
          ' - ' +
          moment(state.dateRangeTo).format('D MMM, YYYY')
        )
      }

      return (
        moment(state.dateRangeFrom).format('D MMM') +
        ' - ' +
        moment(state.dateRangeTo).format('D MMM, YYYY')
      )
    },

    getFullFormattedRange: (state) => {
      let _dateFrom = new Date(state.dateRangeFrom)
      _dateFrom.setHours(0, 0, 0, 0)

      let _dateTo = new Date(state.dateRangeTo)
      _dateTo.setHours(0, 0, 0, 0)

      // Check if is same day
      if (_dateFrom.getTime() === _dateTo.getTime()) {
        return moment(state.dateRangeFrom).format('dddd, Do MMMM YYYY')
      }

      if (_dateFrom.getFullYear() !== _dateTo.getFullYear()) {
        return (
          moment(state.dateRangeFrom).format('D MMMM, YYYY') +
          ' - ' +
          moment(state.dateRangeTo).format('D MMMM, YYYY')
        )
      }

      return (
        moment(state.dateRangeFrom).format('D MMMM') +
        ' - ' +
        moment(state.dateRangeTo).format('D MMMM, YYYY')
      )
    },

    getGlobalDragStatus: (state) => {
      return state.dragInProgress
    },

    getActiveDropZone: (state) => {
      return state.activeDropZone
    },

    prepareSelectedCompany: (state) => {},

    getSelectedCompany: (state) => {
      if (state.me === null) {
        return null
      }

      const storageSelectedCompany = localStorage.getItem('selectedCompany')
      let selectedCompany = {}

      try {
        selectedCompany = JSON.parse(storageSelectedCompany)
      } catch {
        state.selectedCompany = state.companies[0]
        if (
          state.selectedCompany &&
          state.selectedCompany.hasOwnProperty('billingDate')
        ) {
          state.selectedCompany.expirationDays = getDaysToExpiration(
            state.selectedCompany.billingDate
          )
          state.selectedCompany.showExpirationWarning =
            state.selectedCompany.expirationDays <= 14
        }
        return state.selectedCompany
      }

      if (selectedCompany && selectedCompany.hasOwnProperty(state.me.id)) {
        const index = state.companies.find(
          (item) => item.id === selectedCompany[state.me.id]
        )

        if (!index) {
          state.selectedCompany = state.companies[0]

          if (
            state.selectedCompany &&
            state.selectedCompany.hasOwnProperty('billingDate')
          ) {
            state.selectedCompany.expirationDays = getDaysToExpiration(
              state.selectedCompany.billingDate
            )
            state.selectedCompany.showExpirationWarning =
              state.selectedCompany.expirationDays <= 14
          }

          return state.selectedCompany
        }

        state.selectedCompany = index
        if (
          state.selectedCompany &&
          state.selectedCompany.hasOwnProperty('billingDate')
        ) {
          state.selectedCompany.expirationDays = getDaysToExpiration(
            state.selectedCompany.billingDate
          )
          state.selectedCompany.showExpirationWarning =
            state.selectedCompany.expirationDays <= 14
        }
        return state.selectedCompany
      }

      state.selectedCompany = state.companies[0]
      if (
        state.selectedCompany &&
        state.selectedCompany.hasOwnProperty('billingDate')
      ) {
        state.selectedCompany.expirationDays = getDaysToExpiration(
          state.selectedCompany.billingDate
        )
        state.selectedCompany.showExpirationWarning =
          state.selectedCompany.expirationDays <= 14
      }
      return state.selectedCompany
    },

    getSelectedGroupBy: (state) => {
      return state.selectedGroupBy
    },

    getSelectedIdeaGroupBy: (state) => {
      return state.selectedGroupByIdea
    },

    getContentFilter: (state) => {
      return state.contentFilter
    },

    getContentToDelete: (state) => {
      return state.contentToDelete
    },

    getModalConfirmCloseCreateContent: (state) => {
      return state.modalConfirmCloseCreateContent
    },

    getModalConfirmCloseEditContent: (state) => {
      return state.modalConfirmCloseEditContent
    },

    getModalConfirmCloseEditContentData: (state) => {
      return state.modalConfirmCloseEditContentData
    },

    getModalUserRolesInfo: (state) => {
      return state.modalUserRolesInfo
    },

    getModalFeedbackContentId: (state) => {
      return state.modalFeedbackContentId
    },
  },
  mutations: {
    setListCollapsedAll(state, status) {
      state.listCollapsedAll = status
    },

    setIdeasCollapsedAll(state, status) {
      state.ideasCollapsedAll = status
    },

    setFeedbackModal: (state, { status, contentId }) => {
      state.modalFeedbackContentId = contentId
      return (state.modalFeedback = status)
    },

    setUserAvatar(state, { userId, avatar }) {
      const userAvatar = state.userAvatars.find(
        (item) => item.userId === userId
      )
      if (!userAvatar) {
        state.userAvatars.push({
          userId: userId,
          avatar: avatar,
        })
      }
    },

    setCompanyLogo(state, { companyId, logo }) {
      const companyLogo = state.companyLogos.find(
        (item) => item.companyId === companyId
      )
      if (!companyLogo) {
        state.companyLogos.push({
          companyId: companyId,
          logo: logo,
        })
      }
    },

    clearUserAvatars(state) {
      state.userAvatars = []
    },

    clearCompanyLogos(state) {
      state.companyLogos = []
    },

    setModalConfirmCloseCreateContent(state, status) {
      state.modalConfirmCloseCreateContent = status
    },

    setModalConfirmCloseEditContent(state, data) {
      state.modalConfirmCloseEditContent = data.status
      state.modalConfirmCloseEditContentData = data.data
    },

    setModalUserRolesInfo(state, status) {
      state.modalUserRolesInfo = status
    },

    setCompanies(state, companies) {
      state.companies = companies
    },

    setCurrentCompany(state, company) {
      state.companies = company
    },

    setMe(state, me) {
      state.me = me
    },

    setPrevRouteName(state, name) {
      state.prevRouteName = name
    },

    setDayShowExtra(state, id) {
      state.dayShowExtra = id
    },

    setDeletingCodelistId(state, id) {
      state.deletingCodelistId = id
    },

    setIdeasContainerStatus(state, status) {
      state.ideasContainerStatus = status
    },

    setContentToDelete(state, content) {
      state.contentToDelete = content
    },

    setActiveUID(state, uid) {
      state.activeUID = uid
    },

    setUserProfile(state) {
      state.userProfile = new Date().getTime()
    },

    setRefreshToken(state) {
      state.refreshContent = new Date().getTime()
    },

    setRefreshUserAvatar(state) {
      state.refreshUserAvatar = new Date().getTime()
    },

    setRefreshCompanyLogo(state) {
      state.refreshCompanyLogo = new Date().getTime()
    },

    setContactModal(state, modal) {
      state.showContactModal = modal
    },

    setSlickUpdate(state, slick) {
      state.slickUpdate = slick
    },

    setCodelist(state, codelist) {
      state.codelist = {}
      state.codelist = codelist
    },

    setCreateContentDefaultValue(state, data) {
      state.createContentDefaultValue = data
    },

    setCreateContentModalStatus(state, status) {
      state.showCreateContentModal = status
    },

    setDuplicateContentModalId(state, id) {
      return (state.duplicateContentModalId = id)
    },

    setEditContentModalStatus(state, status) {
      state.showEditContentModal = status
    },

    setContentOwner(state, owner) {
      state.owner = owner

      if (owner === 'me') {
        state.owner = state.me.id
      }
    },
    setTags(state, tags) {
      state.tags = tags
    },
    setUsers(state, users) {
      state.users = users
    },
    setChannels(state, channels) {
      state.channels = channels
    },
    setDate(state, date) {
      state.selectedDate = date
    },
    setDateRangeFrom(state, date) {
      state.dateRangeFrom = date
    },
    setDateRangeTo(state, date) {
      state.dateRangeTo = date
    },
    setGlobalDrag(state, status) {
      state.dragInProgress = status
    },
    setActiveDropZone(state, key) {
      state.activeDropZone = key
    },
    setCompany(state, company) {
      if (!state.me) {
        return
      }

      if (!state.me.id) {
        return
      }

      let storageSelectedCompany = {}

      try {
        storageSelectedCompany = JSON.parse(
          localStorage.getItem('selectedCompany')
        )
      } catch {
        storageSelectedCompany = {}
      }

      if (!storageSelectedCompany) {
        storageSelectedCompany = {}
      }
      storageSelectedCompany[state.me.id] = company.id
      localStorage.setItem(
        'selectedCompany',
        JSON.stringify(storageSelectedCompany)
      )
      state.selectedCompany = company

      state.refreshCompanyLogo = new Date().getTime()
    },
    setGroupBy(state, item) {
      state.selectedGroupBy = item
    },
    setGroupByIdea(state, item) {
      state.selectedGroupByIdea = item
    },
    setContentFilter(state, item) {
      state.contentFilter = item
    },
    addToContentFilter(state, item) {
      state.contentFilter.push(item)
    },
  },
  actions: {},
  modules: {},
})
